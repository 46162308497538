<template>
  <slot />
</template>

<script>
export default {
  name: "Clean",
  mounted() {
    const { $events } = useNuxtApp();
    // Loader
    $events.on("loading", ([state, text, fullHeight]) => {
      this.loadingText = text || "loader.loading";
      this.loading = state;
      this.fullHeight = fullHeight;

      // If loading add overflow hidden
      if (state) document.body.classList.add("overflow-hidden");
      else document.body.classList.remove("overflow-hidden");
      // Move the scroll to the top
      window.scrollTo(0, 0);
    });
  }
};
</script>
